import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import AccentSidebarLayout from "src/layouts/AccentSidebarLayout";
import SuspenseLoader from "src/components/SuspenseLoader";
import Guest from "src/components/Guest";
import Authenticated from "src/components/Authenticated";
import AuthenticatedAndVerified from "./components/AuthenticatedAndVerified";
import { RBAC_POLICIES } from "./utils/globalStaticData";

export const renderRoutes = (
  routes,
  is_admin,
  isAuthenticated,
  is_developer
) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const route_id = route.route_id;

          if (is_admin === false && isAuthenticated && is_developer === false) {
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(
                          route.routes,
                          is_admin,
                          isAuthenticated,
                          is_developer
                        )
                      ) : RBAC_POLICIES["read_only"].includes(route_id) ? (
                        <Component {...props} />
                      ) : route.path === `/` ? (
                        <Redirect to="/login" />
                      ) : (
                        <Redirect to="/status/401" />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          } else {
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(
                          route.routes,
                          is_admin,
                          isAuthenticated,
                          is_developer
                        )
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          }
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    path: "/verify",
    guard: Authenticated,
    layout: AccentSidebarLayout,
    routes: [
      {
        exact: true,
        route_id: "read_only",
        path: "/verify/mobile-otp",
        component: lazy(() => import("src/content/dashboards/VerifyMailOtp")),
      },
    ],
  },
  {
    path: "/account",
    guard: Authenticated,
    layout: AccentSidebarLayout,
    routes: [
      {
        exact: true,
        route_id: "read_only",
        path: "/account/onboarding",
        component: lazy(() => import("src/content/dashboards/KYBOnboarding")),
      },
    ],
  },
  {
    path: "/preview/dashboards/profile",
    route_id: "read_only",
    guard: Authenticated,
    layout: AccentSidebarLayout,
    exact: true,
    component: lazy(() => import("src/content/dashboards/Profile")),
  },
  {
    path: "/preview/dashboards/analytics",
    guard: Authenticated,
    layout: AccentSidebarLayout,
    exact: true,
    route_id: "read_only",
    component: lazy(() => import("src/content/dashboards/Analytics")),
  },
  // preview
  {
    path: "/preview",
    guard: AuthenticatedAndVerified,
    layout: AccentSidebarLayout,
    routes: [
      {
        exact: true,
        path: "/preview/dashboards/users",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Users")),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos",
        component: lazy(() => import("src/content/dashboards/Tos")),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos/:currency",
        component: lazy(() =>
          import("src/content/dashboards/Tos/currency-tos")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos-fxrates",
        component: lazy(() => import("src/content/dashboards/Tos/FxRate")),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos-fx-trade",
        component: lazy(() => import("src/content/dashboards/Tos/FxTrade")),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos-fx-trade-form",
        component: lazy(() =>
          import("src/content/dashboards/Tos/FxTrade/fxTradeOpen")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos-receivables",
        component: lazy(() => import("src/content/dashboards/Tos/Recievables")),
      },
      {
        exact: true,
        path: "/preview/dashboards/tos-payables",
        component: lazy(() => import("src/content/dashboards/Tos/Payables")),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-user",
        component: lazy(() => import("src/content/dashboards/Users/AddUser")),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-user/:org_user_id",
        component: lazy(() => import("src/content/dashboards/Users/EditUser")),
      },
      {
        exact: true,
        path: "/preview/dashboards/global-currency-account/:gca_view",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/GlobalCurrencyAccount")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/earn",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Tos/Earn/OpenAccount")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/earn/form",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Tos/Earn/OpenAccount/OpenAccount")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/earn/form/submitted",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Tos/Earn/OpenAccount/FormSubmitted")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/earn/allHoldings",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Tos/Earn")),
      },
      {
        exact: true,
        path: "/preview/dashboards/earn/AddHoldings",
        component: lazy(() =>
          import("src/content/dashboards/Tos/Earn/Holding")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/global-currency-conversion",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/GlobalCurrencyAccountCoversion")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/global-currency-conversion/:fx_conversion_id",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/GlobalCurrencyAccountCoversion/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/fx-rates",
        component: lazy(() =>
          import(
            "src/content/dashboards/GlobalCurrencyAccount/FxRateChart/FxRateTable"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/virtual-accounts",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/VirtualAccount")),
      },
      {
        exact: true,
        path: "/preview/dashboards/create-virtual-account",
        component: lazy(() =>
          import("src/content/dashboards/VirtualAccount/CreateVirtualAccount")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/virtual-accounts/:accountId",
        component: lazy(() =>
          import("src/content/dashboards/VirtualAccount/MoreDetails")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/payments",
        component: lazy(() => import("src/content/dashboards/Payment/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payments/:paymentId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Payment/MoreDetails")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/payin-sender",
        component: lazy(() => import("src/content/dashboards/PayinSender")),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/payin-sender/create-sender",
        component: lazy(() =>
          import("src/content/dashboards/PayinSender/CreateSender")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-payin-sender",
        component: lazy(() =>
          import("src/content/dashboards/PayinSender/EditSender")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin-sender/:sender_id",
        component: lazy(() =>
          import("src/content/dashboards/PayinSender/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/dispute",
        component: lazy(() => import("src/content/dashboards/Dispute/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/dispute/:dispute_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Dispute/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-payout-sender",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Sender/CreateSender")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-sender",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Payout/Sender")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-sender/:senderId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Sender/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-payout-sender",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Sender/EditSender")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-payout-beneficiary",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Beneficiary/CreateBeneficiary")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-beneficiary",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Beneficiary")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-payout-beneficiary",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Beneficiary/EditBeneficiary")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-beneficiary/:beneficiaryId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Beneficiary/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payouts",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Payout/Payout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-payout/:payout_type",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Payout/AddPayout")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout/:payoutId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Payout/Payout/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/bulk-payout",
        component: lazy(() =>
          import("src/content/dashboards/BulkPayout/NewBulkPayout")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/bulk-payout/:bulk_payout_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/BulkPayout/AllBulkPayout/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-bulk-payouts",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/BulkPayout/AllBulkPayout")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/bulk-payouts/all-payouts/:bulk_payout_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/BulkPayout/AllLinkedPayout")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Transactions/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transaction/customerlevel",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/status/CustomerLevel")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transaction/orglevel",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/status/OrgLevel")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions/:transactionId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/MoreDetails/")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/refunds",
        component: lazy(() => import("src/content/dashboards/Refunds/Table")),
      },
      {
        exact: true,
        path: "/preview/dashboards/refunds/:refundId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Refunds/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlements",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Settlements")),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlements/:settlementId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settlements/Summary")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-payout/:payoutId",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settlements/Summary/SettlementPayouts/MoreDetails/detailedView"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoices",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Invoice")),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/:invoiceId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/sessions",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Checkout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/checkout",
        component: lazy(() =>
          import("src/content/dashboards/Checkout/CreateCheckout")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/sessions/:sessionId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Checkout/MoreDetails")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/customers",
        component: lazy(() => import("src/content/dashboards/Customer/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/customers/:customerId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Customer/MoreDetails/")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-customer",
        component: lazy(() =>
          import("src/content/dashboards/Customer/CreateCustomer")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-customer",
        component: lazy(() =>
          import("src/content/dashboards/Customer/EditCustomer")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/create-wallet",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/CreateWallet")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/WalletTable")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/create-wallet-fund-transfer",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer/CreateWFT")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallet-fund-transfer",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/wallet-fund-transfer/:wftId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer/MoreDetails")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/WalletTransactions")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction/settled",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Wallet/WalletTransactions/status/Settled"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction/pending",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Wallet/WalletTransactions/status/Pending"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction/matched",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Wallet/WalletTransactions/status/Matched"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction/unmatched",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Wallet/WalletTransactions/status/Unmatched"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/transaction/:transactionId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/WalletTransactions/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallets/:walletId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/WalletTable/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-webhooks",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Webhook/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-webhooks/:webhookId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Webhook/MoreDetails")
        ),
      },
      {
        exact: true,
        route_id: "read_only",
        path: "/preview/dashboards/setting",
        component: lazy(() => import("src/content/dashboards/Settings")),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-settlement-bank",
        component: lazy(() => import("src/content/dashboards/SettlementBank")),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-settlement-bank",
        component: lazy(() => import("src/content/dashboards/SettlementBank")),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlements",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Settlements")),
      },

      {
        exact: true,
        path: "/preview/dashboards/pricing",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Account/Pricing")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payment-method-setting",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Account/PaymentMethodSetting")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/account-detail",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Account/AccDetails")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/business-detail",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Account/BusinessDetails")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/msa",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Account/Contract")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/developer/api",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/Developer/ApiKey")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/developer/plugin",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/PluginManagement")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/developer/webhook-url",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/Developer/WebhookUrl")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/developer/authentication",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/Developer/Signature")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/setting/security/iplisting",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/Security/IpListing")
        ),
      },

      // {
      //   exact: true,
      //   path: "/preview/dashboards/setting/security/nppdomain",
      //   component: lazy(() =>
      //     import("src/content/dashboards/Settings/NppDomainName/Domain")
      //   ),
      // },

      {
        exact: true,
        path: "/preview/dashboards/setting/notifications",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/Notifications/Notifications")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/setting/webhook-custom-header",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settings/Webhook-custom-header/WebhookCustomHeader"
          )
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/setting/customizedcheckout/logourl",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/CustomizedCheckout/LogoUrl")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/finmo-api",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Api")),
      },

      // {
      //   exact: true,
      //   path: "/preview/dashboards/setting/customizedcheckout/paymentinstructions",
      //   route_id: "read_only",
      //   component: lazy(() =>
      //     import(
      //       "src/content/dashboards/Settings/CustomizedCheckout/PaymentInstructions"
      //     )
      //   ),
      // },
      {
        exact: true,
        path: "/preview/dashboards/setting/customizedcheckout/customtheme",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settings/CustomizedCheckout/CustomTheme"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/customizedcheckout/customsetting",
        route_id: "read_only",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settings/CustomizedCheckout/CustomSetting"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/setting/account-details",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Settings/BankDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/adjustments",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Adjustment")),
      },
      {
        exact: true,
        path: "/preview/dashboards/adjustment/:adjust_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Adjustment/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/fees-adjustments",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/FeesAdjustment")),
      },
      {
        exact: true,
        path: "/preview/dashboards/fees-adjustment/:fees_adjustment_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/FeesAdjustment/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/AuditTrail")),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail/:auditId",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/report",
        route_id: "read_only",
        component: lazy(() => import("src/content/dashboards/Report")),
      },
      {
        exact: true,
        path: "/preview/dashboards/report/:report_id",
        route_id: "read_only",
        component: lazy(() =>
          import("src/content/dashboards/Report/MoreDetails")
        ),
      },
    ],
  },
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/preview/dashboards/analytics" />,
  },
  // login
  {
    exact: true,
    guard: Guest,
    path: "/login",
    component: lazy(() => import("src/content/pages/Auth/Login/Cover")),
  },
  {
    exact: true,
    // guard: Guest,
    path: "/plugin/shopify/onboarding",
    component: lazy(() =>
      import("src/content/dashboards/PluginManagement/Plugins/Shopify/Cover")
    ),
  },
  {
    exact: true,
    guard: Guest,
    path: "/register",
    component: lazy(() => import("src/content/pages/Auth/Register/Cover")),
  },

  {
    exact: true,
    path: "/recover-password",
    component: lazy(() => import("src/content/pages/Auth/RecoverPassword")),
  },
  {
    exact: true,
    guard: Authenticated,
    path: "/email/verification",
    component: lazy(() => import("src/content/pages/Auth/EmailVerification")),
  },
  {
    exact: true,
    path: "/verify-email",
    component: lazy(() =>
      import("src/content/pages/Auth/OrganizationUserEmailVerification")
    ),
  },
  {
    exact: true,
    path: "/new-org-invitation",
    component: lazy(() => import("src/content/pages/Auth/VerifyInvitation")),
  },
  {
    exact: true,
    path: "/merchant-kyb-approval",
    component: lazy(() => import("src/content/pages/Auth/VerifyKYB")),
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() =>
      import("src/content/pages/Auth/OrganizationUserResetPassword")
    ),
  },
  {
    exact: true,
    route_id: "read_only",
    path: "/status/404",
    component: lazy(() => import("src/content/pages/Status/Status404")),
  },
  {
    exact: true,
    route_id: "read_only",
    path: "/status/401",
    component: lazy(() => import("src/content/pages/Status/Status401")),
  },
  {
    exact: true,
    route_id: "read_only",
    path: "/status/500",
    component: lazy(() => import("src/content/pages/Status/Status500")),
  },
  {
    exact: true,
    path: "/status/coming-soon",
    component: lazy(() => import("src/content/pages/Status/ComingSoon")),
  },
  {
    exact: true,
    path: "/status/maintenance",
    component: lazy(() => import("src/content/pages/Status/Maintenance")),
  },
  {
    exact: true,
    route_id: "read_only",
    path: "*",
    component: lazy(() => import("src/content/pages/Status/Status404")),
  },
  // {
  //   path: '*',
  //   layout: BaseLayout,
  //   routes: [
  //     // {
  //     //   exact: true,
  //     //   path: '/',
  //     //   component: Overview
  //     // },
  //     {
  //       component: () => <Redirect to="/" />
  //     }
  //   ]
  // }
];

export default routes;
